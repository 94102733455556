@tailwind base;
@tailwind components;
@tailwind utilities;

body {
   font-family: "Outfit", sans-serif;
   font-optical-sizing: auto;
   background-color: #f9fafb;
}


.display-2xl {
   font-size: 56px;
   line-height: 72px;
   letter-spacing: -2%;
}

.display-lg {
   font-size: 48px;
   line-height: 62px;
   letter-spacing: 0%;
}

.display-md {
   font-size: 46px;
   line-height: 44px;
   letter-spacing: -2%;
}

.display-sm {
   font-size: 30px;
   line-height: 44px;
}

.display-xs {
   font-size: 20px;
   line-height: 32px;
}

.display-xs {
   font-size: 20px;
   line-height: 32px;
}

.text-xl {
   font-size: 20px;
   line-height: 32px;
}

.text-lg {
   font-size: 18px;
   line-height: 28px;
}

.text-md {
   font-size: 16px;
   line-height: 26px;
}

.text-xs {
   font-size: 14px;
   line-height: 24px;
}

.text-secondary-lg {
   font-size: 18px;
   line-height: 30px;
}

.text-secondary-md {
   font-size: 14px;
   line-height: 20px;
}

.text-secondary-sm {
   font-size: 14px;
   line-height: 20px;
}

.brand-primary {
   background-color: #8e4b1a;
}

.brand-secondary {
   background-color: #006400;
}

.typography-light-heading {
   color: #1d2939;
}

.typography-light-subheading {
   color: #344054;
}

.typography-light-body {
   color: #475467;
}

.brand-divider {
   border: 1px solid #d0d5dd;
}

::-webkit-scrollbar {
   display: none !important;
   width: "50px" !important;
}

.scrollbar-active::-webkit-scrollbar {
   display: block !important;
   width: "10px" !important;
}

.scrollbar-active {
   scrollbar-width : thin;
   width: "10px" !important;
   /*ForFirefox*/scrollbar-color: #8e4b1a transparent;
   /*ForFirefox*/border-radius: "20px";
   -webkit-border-radius:"20px";
   -moz-border-radius:"20px";
   -ms-border-radius:"20px";
   -o-border-radius:"20px";
}

 .scrollbar-active::-webkit-scrollbar-thumb {
   background-color: #8e4b1a;
   width: "10px" !important;
   /*Colorofthescrollbar*/border-radius: 10px;
   /*Optional:;
   forroundedcorners*/border-radius: "20px" ;
   -webkit-border-radius: "20px";
   -moz-border-radius: "20px";
   -ms-border-radius: "20px";
   -o-border-radius: "20px";
}
 .scrollbar-active::-webkit-scrollbar-track {
   width: "10px" !important;
   background-color: transparent; /* Color of the track */
 }

